import request from '@/utils/request'

export function getProductList(params) {
    return request({
        url: '/product/page',
        method: 'GET',
        params,
    })
}

export function createProduct (data) {
    return request({
        url: data.id ? `/product/update/${data.id}` : `/product/create`,
        method: 'POST',
        data
    })
}

export function deleteProduct(data) {
    return request({
        url: '/product/delete',
        method: 'POST',
        data,
    })
}

export function getProductFiles(params) {
    return request({
        url: '/product/getProductFiles',
        method: 'GET',
        params,
    })
}

export function bindParam(data) {
    return request({
        url: '/product/bindParameters',
        method: 'POST',
        data,
    })
}

export function bindAAndC(data) {
    return request({
        url: '/product/bindAccessoies',
        method: 'POST',
        data,
    })
}

export function downloadAll(params) {
    return request({
        url: '/product/downloadProductFiles',
        method: 'GET',
        params,
    })
}

export function batProcess(data) {
    return request({
        url: '/afterSaleCare/batProcess',
        method: 'POST',
        data,
    })
}








