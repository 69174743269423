import request from '@/utils/request'

export function getFileList(params) {
    return request({
        url: `/sharedFileLibrary/page`,
        method: 'GET',
        params,
    })
}

export function createFile (data) {
    return request({
        url: data.id ? `/sharedFileLibrary/update/${data.id}` : `/sharedFileLibrary/create`,
        method: 'POST',
        data
    })
}

export function deleteFile(data) {
    return request({
        url: '/sharedFileLibrary/delete',
        method: 'POST',
        data,
    })
}








