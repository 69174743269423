<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addSharedFileLibrary']">新增文件</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="fileType" slot-scope="text">
          <span v-if="text === 1">项目</span>
          <span v-if="text === 2">标准</span>
          <span v-if="text === 3">模板</span>
          <span v-if="text === 4">行政</span>
          <span v-if="text === 5">商务</span>
          <span v-if="text === 6">其他</span>
        </template>
        <template slot="fileStage" slot-scope="text">
          <span v-if="text === 1">项目前期准备</span>
          <span v-if="text === 2">项目中期</span>
          <span v-if="text === 3">项目后期</span>
        </template>
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/download.png" alt="" @click="openFiles(record)" v-has-permission="['downloadSharedFileLibrary']" v-show="record.fileUrl">
          <img src="@/assets/image/common/edit.png" alt="" class="margin-l20" @click="openCreate('edit', record)" v-has-permission="['editSharedFileLibrary']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteSharedFileLibrary']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="filterForm"
            class="drawer-form"
        >
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="文件名称" v-model="filterForm.fileName" @search="onFilter" />
          </a-form-model-item>
          <a-row class="margin-t20">
            <a-form-model-item label="文件类型" prop="fileType">
              <a-select v-model="filterForm.fileType" placeholder="请选择文件类型">
                <a-select-option :value="1">项目</a-select-option>
                <a-select-option :value="2">标准</a-select-option>
                <a-select-option :value="3">模板</a-select-option>
                <a-select-option :value="4">行政</a-select-option>
                <a-select-option :value="5">商务</a-select-option>
                <a-select-option :value="6">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目阶段" prop="fileStage">
              <a-select v-model="filterForm.fileStage" placeholder="请选择项目阶段">
                <a-select-option :value="1">项目前期准备</a-select-option>
                <a-select-option :value="2">项目中期</a-select-option>
                <a-select-option :value="3">项目后期</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}文件` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="文件名称" prop="fileName">
              <a-input placeholder="请输入文件名称" v-model="form.fileName" />
            </a-form-model-item>
            <a-form-model-item label="文件类型" prop="fileType">
              <a-select v-model="form.fileType" placeholder="请选择文件类型">
                <a-select-option :value="1">项目</a-select-option>
                <a-select-option :value="2">标准</a-select-option>
                <a-select-option :value="3">模板</a-select-option>
                <a-select-option :value="4">行政</a-select-option>
                <a-select-option :value="5">商务</a-select-option>
                <a-select-option :value="6">其他</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目阶段" prop="fileStage">
              <a-select v-model="form.fileStage" placeholder="请选择项目阶段">
                <a-select-option :value="1">项目前期准备</a-select-option>
                <a-select-option :value="2">项目中期</a-select-option>
                <a-select-option :value="3">项目后期</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="浏览下载权限" prop="productId">
              <a-select mode="multiple" v-model="form.postIds" placeholder="请选择浏览下载权限">
                <a-select-option :value="item.id" v-for="(item) in jobList" :key="item.id">{{ item.postName }}（{{ item.deptName}}）</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联产品" prop="productId">
              <a-select v-model="form.productId" placeholder="请选择关联产品">
                <a-select-option :value="item.id" v-for="(item) in productList" :key="item.id">{{ item.productName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="上传文件" prop="fileUrl">
              <a-upload-dragger
                  v-model="form.fileUrl"
                  name="file"
                  :multiple="false"
                  action="http://118.190.215.184:8383/file/uploadFile"
                  :headers="headers"
                  :file-list="fileList"
                  accept=".jpg,.jpeg,.gif,.png,.pdf,.doc,.xls,.dat,.txt,.glb"
                  @change="fileChange"
                  :class="{'drag-uploader' : fileList.length}"
              >
                <p class="ant-upload-drag-icon">
                  <a-icon type="cloud-upload" />
                </p>
                <p class="ant-upload-text">
                  多个文件请先存入压缩包
                </p>
              </a-upload-dragger>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button class="margin-l15" icon="close" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getFileList, deleteFile, createFile } from '../../api/project/file'
import { getProductList } from '../../api/product/product'
import storage from 'store'
import { getJobList } from '../../api/humanResources/job'

export default {
  name: 'sharedFileLibrary',
  components: {

  },
  data () {
    return {
      columns: [
        {
          title: '文件名称',
          dataIndex: 'fileName'
        },
        {
          title: '文件类型',
          dataIndex: 'fileType',
          scopedSlots: { customRender: 'fileType' }
        },
        {
          title: '项目阶段',
          dataIndex: 'fileStage',
          scopedSlots: { customRender: 'fileStage' }
        },
        {
          title: '浏览下载权限',
          dataIndex: 'fileAuth'
        },
        {
          title: '关联产品',
          dataIndex: 'productName'
        },
        {
          title: '引用次数',
          dataIndex: 'referNum'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      rules: {
        fileName: [
          { required: true, message: '请输入文件名称', trigger: 'blur' }
        ],
        fileType: [
          { required: true, message: '请选择文件类型', trigger: 'change' }
        ],
        fileUrl: [
          { validator: this.checkFile, trigger: ['change', 'blur'] }
        ]
      },
      productList: [],
      jobList: [],
      headers: {
        'token': storage.get('Access-Token')
      },
      fileList: []
    }
  },
  mounted () {
    this.getList()
    this.getProduct()
    this.getJob()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getFileList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) {
        this.form = JSON.parse(JSON.stringify(item))
        this.fileList = [{
          uid: item.id,
          name: item.fileName,
          status: item.done,
          url: 'http://project.xteknology.com/upload/' + item.fileUrl
        }]
        if (!item.postIds) this.form.postIds = undefined
      } else {
        this.fileList = []
      }
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteFile({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createFile(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    openFiles (item) {
      window.open('http://project.xteknology.com/upload/' + item.fileUrl)
    },
    getProduct () {
      getProductList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.productList = res.data.list
      })
    },
    fileChange (res) {
      if (res.file.response) {
        this.form.fileUrl = res.file.response.data
        setTimeout(() => {
          this.$refs.form.clearValidate('fileUrl', (res) => {console.log(res)})
        },50)
      } else {
        this.form.fileUrl = ''
      }
      this.fileList = res.fileList
    },
    getJob () {
      getJobList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.jobList = res.data.list
      })
    },
    checkFile (rule, value, callback) {
      if (this.form.fileUrl) {
        callback();
      } else {
        return callback(new Error('请选择文件'))
      }
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">
.drag-uploader {
  /deep/ .ant-upload-drag {
    display: none;
  }
}
</style>
