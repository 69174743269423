import request from '@/utils/request'

export function getJobList(params) {
    return request({
        url: '/post/page',
        method: 'GET',
        params,
    })
}

export function getOrgTree(data) {
    return request({
        url: '/dept/tree',
        method: 'POST',
        data,
    })
}

export function createJob(data) {
    return request({
        url: data.id ? `/post/update/${data.id}` : `/post/create`,
        method: 'POST',
        data,
    })
}

export function deleteJob(data) {
    return request({
        url: '/post/delete',
        method: 'POST',
        data,
    })
}





